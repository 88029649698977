import Axios from 'axios'
import axiosRetry from 'axios-retry';
import Api from "../../../util/api/Api";
import Toaster from "../../../util/toaster/Toaster";

//6 MB parts, 5 mb is minimum requirement on aws
export const FILE_CHUNK_SIZE = 6_000_000

//partsUrls = [{partNumber : 1, url : "http://amazon.com/somemoreofthepresigneduploadurl"}]
export async function uploadParts(file, partUrls, onPartCompleted) {
    const axios = Axios.create()
    axiosRetry(axios, {retryDelay: axiosRetry.exponentialDelay});
    delete axios.defaults.headers.put['Content-Type']

    const promises = []
    for (let i = 0; i < partUrls.length; i++) {
        const start = i * FILE_CHUNK_SIZE
        const end = (i + 1) * FILE_CHUNK_SIZE
        const blob = i < partUrls.length
            ? file.slice(start, end)
            : file.slice(start)

        const config = {
            headers: {
                "X-Proxy-Url": partUrls[i].proxyUrl
            }
        }

        promises.push(axios.put( partUrls[i].url, blob, config).finally(onPartCompleted))
    }

    const resParts = await Promise.all(promises)
    return resParts.map((part, index) => ({
        eTag: part.headers.etag,
        partNumber: index + 1
    }))
}

// etagsPerParts = [{eTag = "hbtyhtfwelfojrgiler", partNumber: 1}]
export async function finishUpload(fileName, uploadId, etagsPerParts, successCallback, failureCallback) {
    function onSuccess(response) {
        successCallback(response.data);
    }

    function onLocalFailure(error) {
        failureCallback(error);
    }

    function onValidate(response) {
    }

    function onServerFailure(error) {
        failureCallback(error);
    }

    const postData = {
        "parts": etagsPerParts,
        "uploadId": uploadId,
        "fileName": fileName
    };

    Api.post('upload/closeUpload', onValidate, onSuccess, onLocalFailure, onServerFailure, {}, postData)
}