import React from 'react';
import {ThemedH1, ThemedH4} from "../Elements";
import styled from "styled-components";
import SURFButton from "../styled-components/buttons/SURFButton";
import {desktopSideMenuWidth, majorelle, majorelleLight, spaceCadet} from "../Mixins";
import AppStorage, {StorageKey} from "../util/AppStorage";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGlobalState} from "../util/GlobalState";

function PublishPublicationViewHeader(props) {

    const {t} = useTranslation();
    const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useGlobalState('isSideMenuCollapsed', false);

    const {
        subtitle,
        title,
        onStop,
        onSave
    } = props;

    return (
        <EditPublicationHeaderRoot isSideMenuCollapsed={isSideMenuCollapsed}>
            <LeftContent>
                <StepIndicator>{title}</StepIndicator>
                <StepTitle>{subtitle}</StepTitle>
            </LeftContent>

            <RightContent>
                <SURFButton
                    text={t("merge_footer.stop")}
                    backgroundColor={spaceCadet}
                    highlightColor={majorelleLight}
                    border={"2px solid white"}
                    padding={"0 40px 0 40px"}
                    onClick={onStop}
                />
                <SURFButton
                    text={t("add_publication.button_save")}
                    backgroundColor={majorelle}
                    highlightColor={majorelleLight}
                    border={"2px solid white"}
                    padding={"0 40px 0 40px"}
                    onClick={onSave}
                />
            </RightContent>
        </EditPublicationHeaderRoot>
    )
}

const EditPublicationHeaderRoot = styled.div`
    background: linear-gradient(270deg, #F8F8F8 0%, #F0F0F0 82.57%);
    max-width: 1760px;
    width: ${props => props.isSideMenuCollapsed ? "100%" : `calc(100% - ${desktopSideMenuWidth})`};
    height: 130px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 160px 10px 160px;
    margin: ${props => props.isSideMenuCollapsed ? '0px auto' : `0px 0px 0px ${desktopSideMenuWidth}`};
    transition: all 0.2s ease;
    transition-property: width padding margin;
`;

const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const RightContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 9px;
`;

const StepIndicator = styled(ThemedH4)``;

const StepTitle = styled(ThemedH1)``;

export default PublishPublicationViewHeader;