export class UpdateTaskCountEvent extends Event {
    constructor(data) {
        super("UpdateTaskCountEvent");
        this.data = data
    }
}

export class CollapsePersonMergeFooterEvent extends Event {
    constructor(value) {
        super("CollapsePersonMergeFooterEvent");
        this.data = {value: value}
    }
}