import React, {useEffect, useRef, useState} from "react";
import './formfield.scss'
import FormFieldHelper from "../../util/FormFieldHelper";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAlignLeft,
    faCaretLeft,
    faCaretRight,
    faChevronDown,
    faChevronUp,
    faLink, fas, faScrewdriver,
    faShare, faShareAlt, faTools,
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import RepoItemHelper from "../../util/RepoItemHelper";
import {PersonField} from "./personfield/PersonField";
import {FileField} from "./filefield/FileField";
import styled from "styled-components";
import {DragHandle, RepoItemField} from "./repoitem/RepoItemField";
import {SwitchField} from "./switch/Switch";
import SingleDatePickerField from "./singledatepicker/SingleDatePickerField";
import {CheckBoxField} from "./checkbox/Checkbox";
import {SelectField} from "./select/SelectField";
import {TextAreaField} from "./textarea/TextArea";
import {TagField} from "./tag/TagField";
import {TextField} from "./text/TextField";
import {EmailField} from "./email/EmailField";
import {DisciplineField} from "./discipline/DisciplineField";
import {LectorateField} from "./lectorate/LectorateField";
import {SwitchRowField} from "./switchrow/SwitchRowField";
import {SingleRepoItemField} from "./singlerepoitemfield/SingleRepoItemField";
import {OrganisationDropdownField} from "./organisationdropdown/OrganisationDropdownField";
import MultiSelectDropdown from "./multiselectdropdown/MultiSelectDropdown";
import {HelperFunctions} from "../../util/HelperFunctions";
import VerificationPopup from "../../verification/VerificationPopup";
import {NumberField} from "./number/NumberField";
import Api from "../../util/api/Api";
import MultiSelectSuborganisation from "./multiselectsuborganisation/MultiSelectSuborganisation";
import MultiSelectPublisher from "./multiselectpublisher/MultiSelectPublisher"
import {DoiField} from "./doi/DoiField";
import {TreeMultiSelectField} from "./treemultiselect/TreeMultiSelectField";
import ValidationHelper from "../../util/ValidationHelper";
import {ThemedA, ThemedH3, ThemedH4, ThemedH5} from "../../Elements";
import {
    cultured,
    majorelle,
    maxNumberOfLines,
    openSans,
    roundedBackgroundPointyUpperLeft
} from "../../Mixins";
import {useForm} from "react-hook-form";
import i18n from "i18next";
import {useOutsideElementClicked} from "../../util/hooks/useOutsideElementClicked";
import IconButtonText from "../buttons/iconbuttontext/IconButtonText";

export function Form(props) {
    const formFieldHelper = new FormFieldHelper();
    const {t} = useTranslation();
    const sections = RepoItemHelper.getSectionsFromSteps(props.repoItem)
    const [extendedSections, setExtendedSections] = useState([]);

    const allSectionsAreExtended = (extendedSections.length >= 0 && extendedSections.length < sections.length) || extendedSections.length === 0


    useEffect(() => {
        if (props.repoItem) {
            if (props.isEditing || props.isPublicationFlow) {
                extendAllSections()
            } else {
                setExtendedSections([])
            }
        }
    }, [props.isEditing, props.isPublicationFlow, props.repoItem])

    function collapseOrExtendAllSections() {
        if ((extendedSections.length >= 0 && extendedSections.length < sections.length) || extendedSections.length === 0) {
            extendAllSections()
        } else {
            setExtendedSections([])
        }
    }

    function collapseOrExtendSection(section) {
        if (extendedSections.includes(section)){
            setExtendedSections(extendedSections.filter((collapsedSection) => collapsedSection.id !== section.id))
        } else {
            setExtendedSections(collapsedSections => [...collapsedSections, section])
        }
    }

    function extendAllSections() {
        sections.forEach((section) => {
            if (!extendedSections.includes(section)){
                setExtendedSections(collapsedSections => [...collapsedSections, section])
            }
        })
    }

    function checkIfFormSectionIsActive(sectionId){
        if (props.containsHiddenSections === true){
            if (props.sectionsToShow) {
                const sectionsToShowIds = props.sectionsToShow.map((section) => {
                    return section.id
                })
                return sectionsToShowIds.includes(sectionId)
            }
            return false
        } else {
            return true
        }
    }

    function getSectionsFromStep(step){
        let sections = [];
        step.templateSections.forEach(section => {
            sections.push(section)
        })
        return sections
    }

    return (

        <form id={`surf-form${props.formId ? "-" + props.formId : ""}`} onSubmit={props.onSubmit}>
            {!props.isPublicationFlow &&
                <FoldButton onClick={() => {
                    collapseOrExtendAllSections()
                }}>
                    <FontAwesomeIcon icon={allSectionsAreExtended ? faChevronDown : faChevronUp}/>
                    <div>{allSectionsAreExtended ? t("publication.sections.extend_all") : t("publication.sections.collapse_all")}</div>
                </FoldButton>
            }
            <FormSectionsContainer>
                {
                    props.repoItem.steps.map((step, i ) => {
                        return (
                            <Step>
                                {!props.isPublicationFlow && <StepTitle>{t('language.current_code') === 'nl' ? step.subtitleNL : step.subtitleEN}</StepTitle>}
                                <StepContainer >
                                    {
                                        getSectionsFromStep(step).map((section, i) => {
                                                const fieldRows = [];
                                                let fieldsInFieldRow = []

                                                section.fields.forEach(field => {
                                                    if (field.isSmallField) {
                                                        fieldsInFieldRow.push(field);
                                                        if (fieldsInFieldRow.length > 3) {
                                                            pushFieldRow(fieldsInFieldRow);
                                                            fieldsInFieldRow = [];
                                                        }
                                                    } else {
                                                        if (fieldsInFieldRow.length > 0) {
                                                            pushFieldRow(fieldsInFieldRow);
                                                            fieldsInFieldRow = [];
                                                        }
                                                        fieldsInFieldRow.push(field);
                                                        pushFieldRow(fieldsInFieldRow);
                                                        fieldsInFieldRow = [];
                                                    }
                                                });

                                                pushFieldRow(fieldsInFieldRow);

                                                function pushFieldRow(fieldsInFieldRow) {
                                                    fieldRows.push(
                                                        <div className={"form-field-container"} key={'container_' + fieldRows.length}>
                                                            {
                                                                fieldsInFieldRow.map((fieldInRow) => {
                                                                    let fieldLabel = ((t('language.current_code') === 'nl' ? fieldInRow.titleNL : fieldInRow.titleEN) ?? '')
                                                                    if (!fieldLabel || fieldLabel.length === 0) {
                                                                        fieldLabel = "\u00a0";
                                                                    }

                                                                    let fieldDescription = ((t('language.current_code') === 'nl' ? fieldInRow.descriptionNL : fieldInRow.descriptionEN) ?? '');

                                                                    if (fieldInRow.fieldType.toLowerCase() === 'switch-row') {
                                                                        return <SwitchRowField key={fieldInRow.key}
                                                                                               onValueChanged={(changedValue) => {
                                                                                                   props.onValueChanged(fieldInRow, changedValue)
                                                                                               }}
                                                                                               setValue={props.setValue}
                                                                                               label={fieldLabel}
                                                                                               description={fieldDescription}
                                                                                               defaultValue={formFieldHelper.getFieldAnswer(props.repoItem, fieldInRow)}
                                                                                               name={fieldInRow.key}
                                                                                               register={props.register}
                                                                                               isRequired={fieldInRow.required}
                                                                                               readonly={props.readonly || fieldInRow.readonly === 1}
                                                                                               hidden={fieldInRow.hidden === 1}/>
                                                                    } else {
                                                                        fieldLabel = fieldLabel.toUpperCase();
                                                                        const debouncedQueryFunction = HelperFunctions.debounce(HelperFunctions.getGetOptionsCallForFieldKey(fieldInRow.key,
                                                                            (resultOption) => {
                                                                                return {
                                                                                    "label": t('language.current_code') === 'nl' ? resultOption.labelNL : resultOption.labelEN,
                                                                                    "labelNL": resultOption.labelNL,
                                                                                    "labelEN": resultOption.labelEN,
                                                                                    "coalescedLabelNL": resultOption.coalescedLabelNL,
                                                                                    "coalescedLabelEN": resultOption.coalescedLabelEN,
                                                                                    "value": resultOption.id
                                                                                }
                                                                            }));

                                                                        const fieldType = formFieldHelper.getFieldType(fieldInRow.fieldType)
                                                                        const fieldAnswer = formFieldHelper.getFieldAnswer(props.repoItem, fieldInRow)
                                                                        return <FormField key={fieldInRow.key}
                                                                                          hideField={(fieldType === 'repoitems') && !fieldAnswer}
                                                                                          getOptions={debouncedQueryFunction}
                                                                                          classAddition={fieldInRow.isSmallField ? 'small' : ''}
                                                                                          type={formFieldHelper.getFieldType(fieldInRow.fieldType)}
                                                                                          retainOrder={fieldInRow.retainOrder}
                                                                                          onValueChanged={(changedValue) => {
                                                                                              props.onValueChanged(fieldInRow, changedValue)
                                                                                          }}
                                                                                          label={fieldLabel}
                                                                                          isRequired={fieldInRow.required}
                                                                                          options={formFieldHelper.getFieldOptions(fieldInRow)}
                                                                                          defaultValue={fieldAnswer}
                                                                                          tooltip={t('language.current_code') === 'nl' ? fieldInRow.infoTextNL : fieldInRow.infoTextEN}
                                                                                          file={props.file}
                                                                                          person={props.person}
                                                                                          error={props.errors[fieldInRow.key]}
                                                                                          name={fieldInRow.key}
                                                                                          register={props.register}
                                                                                          setValue={props.setValue}
                                                                                          readonly={props.readonly || fieldInRow.readOnly === 1 || fieldInRow.readOnly === true}
                                                                                          hidden={fieldInRow.hidden === 1}
                                                                                          repoItem={props.repoItem}
                                                                                          relatedRepoItem={props.relatedRepoItem}
                                                                                          validationRegex={fieldInRow.validationRegex}
                                                                                          formReducerState={props.formReducerState}/>
                                                                    }
                                                                })
                                                            }
                                                        </div>);
                                                }

                                                return (
                                                    <FormSection isActive={checkIfFormSectionIsActive(section.id)} isExtended={extendedSections.includes(section)} key={section.id} id={section.id}>
                                                        {
                                                            props.showSectionHeaders && <FormSectionHeader isExtended={extendedSections.includes(section)}>
                                                                <SectionColumn>
                                                                    <SectionRow>
                                                                        <SectionHeader className={"flex"} onClick={() => collapseOrExtendSection(section)}>
                                                                            <SectionIcon>
                                                                                {getSectionIcon(section.icon)}
                                                                            </SectionIcon>
                                                                            <SectionTitleWrapper>
                                                                                { props.isPublicationFlow ?
                                                                                    <SectionTitleH3>{t('language.current_code') === 'nl' ? section.titleNL : section.titleEN}</SectionTitleH3>
                                                                                    :
                                                                                    <SectionTitleH5>{t('language.current_code') === 'nl' ? section.titleNL : section.titleEN}</SectionTitleH5>
                                                                                }
                                                                                { (section.subtitleNL || section.subtitleEN) &&
                                                                                <SectionSubtitle>{t('language.current_code') === 'nl' ? `  -  ${section.subtitleNL}` : ` - ${section.subtitleEN}`}</SectionSubtitle>
                                                                                }
                                                                            </SectionTitleWrapper>
                                                                        </SectionHeader>
                                                                        <IconButtonText
                                                                            faIcon={extendedSections.includes(section) ? faChevronUp : faChevronDown}
                                                                            onClick={() => {
                                                                                collapseOrExtendSection(section);
                                                                            }}
                                                                        />
                                                                    </SectionRow>
                                                                </SectionColumn>
                                                            </FormSectionHeader>
                                                        }
                                                            <FieldRowDisplay isShown={extendedSections.includes(section)}>
                                                                {fieldRows}
                                                            </FieldRowDisplay>
                                                    </FormSection>
                                                )
                                            }
                                        )
                                    }
                                </StepContainer>
                            </Step>
                        )
                    })
                }

                {
                    props.extraContent
                }
            </FormSectionsContainer>
        </form>
    )

    function getSectionIcon(iconString){
        let icon;

        if (iconString) {
            switch (iconString.toUpperCase()) {
                case 'UPLOAD': icon = faUpload; break;
                case 'LINK': icon = faLink; break;
                case 'SHARE': icon = faShareAlt; break;
                case 'TOOLS': icon = faTools; break;
                default: icon = faAlignLeft;
            }
            return <FontAwesomeIcon color={majorelle} icon={icon} />
        }

        return null
    }
}

export function IndependentForm(props) {
    /*  TODO: All pages where the Form() component is used should be refactored to make use of the IndependentForm() component.

        This component is an exact copy of Form(). The only difference is that the form in this component manages it's own state
        while the Form() component expects the useForm() handles to be passed to it as props, this is bad practice as it's the responsibility
        of a form to manage it's own state. The usage of the Form() component caused problems when we tried to make use of multiple forms on one page,
        because useForm() should only be used once in a single component.
        
    */

    const {register, handleSubmit, errors, setValue, getValues, trigger} = useForm();
    const formFieldHelper = new FormFieldHelper();
    const {t} = useTranslation();

    const sections = RepoItemHelper.getSectionsFromSteps(props.repoItem)

    return <form
        id={`surf-form${props.formId ? "-" + props.formId : ""}`}
        onSubmit={handleSubmit((formData) => {
            props.onSubmit(formData)
        }, props.onSubmitError)}
    >
        <FormSectionsContainer>
            {
                sections.map((section, i) => {
                        const fieldRows = [];
                        let fieldsInFieldRow = []

                        section.fields.forEach(field => {
                            if (field.isSmallField) {
                                fieldsInFieldRow.push(field);
                                if (fieldsInFieldRow.length > 3) {
                                    pushFieldRow(fieldsInFieldRow);
                                    fieldsInFieldRow = [];
                                }
                            } else {
                                if (fieldsInFieldRow.length > 0) {
                                    pushFieldRow(fieldsInFieldRow);
                                    fieldsInFieldRow = [];
                                }
                                fieldsInFieldRow.push(field);
                                pushFieldRow(fieldsInFieldRow);
                                fieldsInFieldRow = [];
                            }
                        });

                    pushFieldRow(fieldsInFieldRow);

                    function pushFieldRow(fieldsInFieldRow) {
                        fieldRows.push(
                            <div className={"form-field-container"} key={'container_' + fieldRows.length}>
                                {
                                    fieldsInFieldRow.map((fieldInRow) => {
                                        let fieldLabel = ((t('language.current_code') === 'nl' ? fieldInRow.titleNL : fieldInRow.titleEN) ?? '')
                                        if (!fieldLabel || fieldLabel.length === 0) {
                                            fieldLabel = "\u00a0";
                                        }

                                        let fieldDescription = ((t('language.current_code') === 'nl' ? fieldInRow.descriptionNL : fieldInRow.descriptionEN) ?? '');

                                        if (fieldInRow.fieldType.toLowerCase() === 'switch-row') {
                                            return <SwitchRowField key={fieldInRow.key}
                                                                   onValueChanged={(changedValue) => {
                                                                       props.onValueChanged(fieldInRow, changedValue)
                                                                   }}
                                                                   setValue={setValue}
                                                                   label={fieldLabel}
                                                                   description={fieldDescription}
                                                                   defaultValue={formFieldHelper.getFieldAnswer(props.repoItem, fieldInRow)}
                                                                   name={fieldInRow.key}
                                                                   register={register}
                                                                   isRequired={fieldInRow.required}
                                                                   readonly={props.readonly || fieldInRow.readonly === 1}
                                                                   hidden={fieldInRow.hidden === 1}/>
                                        } else {
                                            fieldLabel = fieldLabel.toUpperCase();
                                            const debouncedQueryFunction = HelperFunctions.debounce(HelperFunctions.getGetOptionsCallForFieldKey(fieldInRow.key,
                                                (resultOption) => {
                                                    return {
                                                        "label": t('language.current_code') === 'nl' ? resultOption.labelNL : resultOption.labelEN,
                                                        "labelNL": resultOption.labelNL,
                                                        "labelEN": resultOption.labelEN,
                                                        "coalescedLabelNL": resultOption.coalescedLabelNL,
                                                        "coalescedLabelEN": resultOption.coalescedLabelEN,
                                                        "value": resultOption.id
                                                    }
                                                }));
                                            return <FormField key={fieldInRow.key}
                                                              getOptions={debouncedQueryFunction}
                                                              classAddition={fieldInRow.isSmallField ? 'small' : ''}
                                                              type={formFieldHelper.getFieldType(fieldInRow.fieldType)}
                                                              retainOrder={fieldInRow.retainOrder}
                                                              onValueChanged={(changedValue) => {
                                                                  props.onValueChanged(fieldInRow, changedValue)
                                                              }}
                                                              label={fieldLabel}
                                                              isRequired={fieldInRow.required}
                                                              options={formFieldHelper.getFieldOptions(fieldInRow)}
                                                              defaultValue={formFieldHelper.getFieldAnswer(props.repoItem, fieldInRow)}
                                                              tooltip={t('language.current_code') === 'nl' ? fieldInRow.infoTextNL : fieldInRow.infoTextEN}
                                                              file={props.file}
                                                              person={props.person}
                                                              error={errors[fieldInRow.key]}
                                                              name={fieldInRow.key}
                                                              register={register}
                                                              setValue={setValue}
                                                              readonly={props.readonly || fieldInRow.readOnly === 1 || fieldInRow.readOnly === true}
                                                              hidden={fieldInRow.hidden === 1}
                                                              repoItem={props.repoItem}
                                                              relatedRepoItem={props.relatedRepoItem}
                                                              validationRegex={fieldInRow.validationRegex}
                                                              formReducerState={props.formReducerState}/>
                                        }
                                    })
                                }
                            </div>);
                        }

                        return (
                            <FormSection isActive={true} key={section.id} >
                                {
                                    props.showSectionHeaders && <FormSectionHeader>
                                        <SectionColumn>
                                            <SectionRow>
                                                <SectionHeader>{t('language.current_code') === 'nl' ? section.titleNL : section.titleEN}</SectionHeader>
                                            </SectionRow>
                                            <SectionSubtitle>{t('language.current_code') === 'nl' ? section.subtitleNL : section.subtitleEN}</SectionSubtitle>
                                        </SectionColumn>
                                    </FormSectionHeader>
                                }
                                {fieldRows}
                                {section === sections[sections.length - 1] && props.submitButton}
                            </FormSection>
                        )
                    }
                )
            }
            {
                props.extraContent
            }
        </FormSectionsContainer>
    </form>
}

export function FormField(props) {
    const {t} = useTranslation();

    const useGreyBackground = props.type === "tree-multiselect"

    return <div className={"form-field " + props.classAddition + ((props.hidden) ? " hidden" : "")}>
        <div className="form-row">
            <div className={"required-indicator" + ((props.isRequired && !props.readonly) ? "" : " hidden")}>
                {
                    !props.hideRequired && <Required/>
                }
            </div>
            <div className={`form-column ${useGreyBackground && 'grey-background'}`}>
                {props.hideField ? null :
                    <>
                        <Label text={props.label} hardHint={props.hardHint}/>
                            <div className="form-row">
                                <InputField
                                    type={props.type}
                                    hardHint={props.hardHint}
                                    readonly={props.readonly}
                                    retainOrder={props.retainOrder}
                                    placeholder={props.placeholder}
                                    extraValidation={props.extraValidation}
                                    defaultValue={props.defaultValue}
                                    isValid={props.isValid}
                                    hasError={props.error}
                                    isSearchable={props.isSearchable}
                                    options={props.options}
                                    isRequired={props.isRequired}
                                    getOptions={props.getOptions}
                                    onValueChanged={props.onValueChanged}
                                    onValueChangedUnchecked={props.onValueChangedUnchecked}
                                    validationRegex={props.validationRegex}
                                    file={props.file}
                                    person={props.person}
                                    register={props.register}
                                    repoItem={props.repoItem}
                                    relatedRepoItem={props.relatedRepoItem}
                                    name={props.name}
                                    setValue={props.setValue}
                                    formReducerState={props.formReducerState}
                                    inputRef={props.inputRef}/>
                                {props.tooltip && <Tooltip text={props.tooltip}/>}
                            </div>
                        <div className={"field-error " + (props.error ? '' : 'hidden')}>{props.error ? errorToLabel(props.error) : 'No error'}</div>
                    </>
                }
            </div>
        </div>
    </div>
}

export function errorToLabel(error) {
    switch (error.type) {
        case 'required':
            return i18n.t('error_message.field_required');
        default:
            return i18n.t('error_message.field_invalid');
    }
}

export function InputField(props) {
    const {t} = useTranslation();

    //onChange returns a string, so if we want to correctly use radio, checkboxes and selects, we need to convert it to an int before posting the onChange value
    let onChange = (v) => {
        if (props.onValueChanged) {
            if (v === "") {
                v = null;
            }
            props.onValueChanged(v);
        }
    };

    switch (props.type) {
        case "email":
            return <EmailField readonly={props.readonly}
                               defaultValue={props.defaultValue}
                               placeholder={props.placeholder}
                               isValid={props.isValid}
                               isRequired={props.isRequired}
                               hasError={props.hasError}
                               onChange={(event) => onChange(event.target.value)}
                               register={props.register}
                               name={props.name}
                               formReducerState={props.formReducerState}/>;
        case "doi":
            return <DoiField readonly={props.readonly}
                             defaultValue={props.defaultValue}
                             isRequired={props.isRequired}
                             placeholder={props.placeholder}
                             isValid={props.isValid}
                             repoItem={props.repoItem}
                             hasError={props.hasError}
                             setValue={props.setValue}
                             onChange={(event) => onChange(event.target.value)}
                             onValueChangedUnchecked={props.onValueChangedUnchecked}
                             register={props.register}
                             name={props.name}
                             validationRegex={props.validationRegex}
                             formReducerState={props.formReducerState}
                             inputRef={props.inputRef}/>;
        case "text":
            return <TextField readonly={props.readonly}
                              defaultValue={props.defaultValue}
                              isRequired={props.isRequired}
                              hardHint={props.hardHint}
                              extraValidation={props.extraValidation}
                              placeholder={props.placeholder}
                              isValid={props.isValid}
                              hasError={props.hasError}
                              onChange={(event) => onChange(event.target.value)}
                              onValueChangedUnchecked={props.onValueChangedUnchecked}
                              register={props.register}
                              name={props.name}
                              validationRegex={props.validationRegex}
                              formReducerState={props.formReducerState}
                              inputRef={props.inputRef}/>;
        case "number":
            return <NumberField readonly={props.readonly}
                                defaultValue={props.defaultValue}
                                isRequired={props.isRequired}
                                placeholder={props.placeholder}
                                isValid={props.isValid}
                                hasError={props.hasError}
                                onChange={(event) => onChange(event.target.value)}
                                onValueChangedUnchecked={props.onValueChangedUnchecked}
                                register={props.register}
                                name={props.name}
                                validationRegex={props.validationRegex}
                                formReducerState={props.formReducerState}
                                inputRef={props.inputRef}/>;
        case "dropdowntag":
            return <MultiSelectDropdown readonly={props.readonly}
                                        defaultValue={props.defaultValue}
                                        placeholder={props.placeholder}
                                        allowCustomOption={true}
                                        isValid={props.isValid}
                                        options={props.options}
                                        hasError={props.hasError}
                                        isRequired={props.isRequired}
                                        onChange={(event) => onChange(event)}
                                        register={props.register}
                                        name={props.name}
                                        getOptions={props.getOptions}
                                        setValue={props.setValue}/>;
        case "tag":
            return <TagField readonly={props.readonly}
                             defaultValue={props.defaultValue}
                             isRequired={props.isRequired}
                             isValid={props.isValid}
                             options={props.options}
                             hasError={props.hasError}
                             onChange={(event) => onChange(event.target.value)}
                             register={props.register}
                             setValue={props.setValue}
                             name={props.name}/>;
        case "textarea":
            return <TextAreaField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  placeholder={props.placeholder}
                                  isValid={props.isValid}
                                  hasError={props.hasError}
                                  isRequired={props.isRequired}
                                  onChange={(event) => onChange(event.target.value)}
                                  register={props.register}
                                  validationRegex={props.validationRegex}
                                  name={props.name}/>;
        case "dropdown":
            return <SelectField readonly={props.readonly}
                                isSearchable={props.isSearchable}
                                defaultValue={props.defaultValue}
                                placeholder={props.placeholder}
                                isValid={props.isValid}
                                options={props.options}
                                retainOrder={props.retainOrder}
                                hasError={props.hasError}
                                isRequired={props.isRequired}
                                onChange={(event) => onChange(event)}
                                register={props.register}
                                name={props.name}
                                setValue={props.setValue}/>;
        case "multiselectdropdown":
            return <MultiSelectDropdown readonly={props.readonly}
                                        defaultValue={props.defaultValue}
                                        placeholder={props.placeholder}
                                        isValid={props.isValid}
                                        options={props.options}
                                        hasError={props.hasError}
                                        isRequired={props.isRequired}
                                        onChange={(event) => onChange(event)}
                                        register={props.register}
                                        name={props.name}
                                        getOptions={props.getOptions}
                                        setValue={props.setValue}/>;
        case "multiselectsuborganisation":
            return <MultiSelectSuborganisation readonly={props.readonly}
                                               defaultValue={props.defaultValue}
                                               placeholder={props.placeholder}
                                               isValid={props.isValid}
                                               options={props.options}
                                               hasError={props.hasError}
                                               isRequired={props.isRequired}
                                               onChange={(event) => onChange(event)}
                                               register={props.register}
                                               name={props.name}
                                               setValue={props.setValue}/>;

        case "multiselectsuborganisationswitch":
            return <MultiSelectSuborganisation readonly={props.readonly}
                                               defaultValue={props.defaultValue}
                                               placeholder={props.placeholder}
                                               isValid={props.isValid}
                                               showInactiveSwitch={true}
                                               options={props.options}
                                               hasError={props.hasError}
                                               isRequired={props.isRequired}
                                               onChange={(event) => onChange(event)}
                                               register={props.register}
                                               name={props.name}
                                               setValue={props.setValue}/>;
        case "multiselectpublisherswitch":
            return <MultiSelectPublisher readonly={props.readonly}
                                         defaultValue={props.defaultValue}
                                         placeholder={props.placeholder}
                                         isValid={props.isValid}
                                         showInactiveSwitch={true}
                                         options={props.options}
                                         hasError={props.hasError}
                                         isRequired={props.isRequired}
                                         onChange={(event) => onChange(event)}
                                         register={props.register}
                                         name={props.name}
                                         setValue={props.setValue}/>;
        case "multiselectpublisher":
            return <MultiSelectPublisher readonly={props.readonly}
                                         defaultValue={props.defaultValue}
                                         placeholder={props.placeholder}
                                         isValid={props.isValid}
                                         options={props.options}
                                         hasError={props.hasError}
                                         isRequired={props.isRequired}
                                         onChange={(event) => onChange(event)}
                                         register={props.register}
                                         name={props.name}
                                         setValue={props.setValue}/>;

        case "discipline":
            return <DisciplineField readonly={props.readonly}
                                    defaultValue={props.defaultValue}
                                    placeholder={props.placeholder}
                                    isValid={props.isValid}
                                    hasError={props.hasError}
                                    isRequired={props.isRequired}
                                    onChange={(event) => onChange(event)}
                                    register={props.register}
                                    name={props.name}
                                    setValue={props.setValue}/>;
        case "lectorate":
            return <LectorateField readonly={props.readonly}
                                   defaultValue={props.defaultValue}
                                   placeholder={props.placeholder}
                                   isValid={props.isValid}
                                   hasError={props.hasError}
                                   isRequired={props.isRequired}
                                   onChange={(event) => onChange(event)}
                                   register={props.register}
                                   name={props.name}
                                   setValue={props.setValue}/>;
        case "institute":
            return <OrganisationDropdownField readonly={props.readonly}
                                              defaultValue={props.defaultValue}
                                              placeholder={props.placeholder}
                                              isSearchable={props.isSearchable}
                                              isValid={props.isValid}
                                              hasError={props.hasError}
                                              isRequired={props.isRequired}
                                              onChange={(event) => onChange(event)}
                                              register={props.register}
                                              name={props.name}
                                              setValue={props.setValue}/>;
        case "checkbox":
            return <CheckBoxField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  isRequired={props.isRequired}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}
                                  register={props.register}
                                  name={props.name}/>;
        case "singledatepicker":
            return <SingleDatePickerField readonly={props.readonly}
                                          defaultValue={props.defaultValue}
                                          placeholder={props.placeholder}
                                          isRequired={props.isRequired}
                                          isValid={props.isValid}
                                          options={props.options}
                                          hasError={props.hasError}
                                          onChange={(event) => onChange(event)}
                                          register={props.register}
                                          name={props.name}
                                          setValue={props.setValue}/>;
        case "switch":
            return <SwitchField readonly={props.readonly}
                                defaultValue={props.defaultValue}
                                isValid={props.isValid}
                                hasError={props.hasError}
                                placeholder={props.placeholder}
                                onChange={(event) => onChange(event)}
                                setValue={props.setValue}
                                register={props.register}
                                name={props.name}/>;
        case "datetime":
            return <DateTimeField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}/>;
        case "personinvolved":
            return <RepoItemField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  hasFileDrop={false}
                                  isRequired={props.isRequired}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}
                                  register={props.register}
                                  name={props.name}
                                  itemToComponent={getPersonInvolvedRepoItemRow}
                                  setValue={props.setValue}
                                  showEmptyState={true}
                                  addText={t('personinvolved_field.add')}
                                  emptyText={t('personinvolved_field.empty')}
                                  formReducerState={props.formReducerState}/>;
        case "repoitemresearchobject":
            return <RepoItemField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  hasFileDrop={false}
                                  isRequired={props.isRequired}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}
                                  register={props.register}
                                  name={props.name}
                                  itemToComponent={getRelatedResearchObjectRepoItemRow}
                                  setValue={props.setValue}
                                  showEmptyState={true}
                                  addText={t('repoitemresearchobject_field.add')}
                                  emptyText={t('repoitemresearchobject_field.empty')}
                                  formReducerState={props.formReducerState}/>;
        case "repoitemlink":
            return <RepoItemField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  hasFileDrop={false}
                                  isRequired={props.isRequired}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}
                                  register={props.register}
                                  name={props.name}
                                  itemToComponent={getRelatedLinkRepoItemRow}
                                  setValue={props.setValue}
                                  showEmptyState={true}
                                  addText={t("link_field.add")}
                                  emptyText={t('link_field.empty')}
                                  formReducerState={props.formReducerState}/>;
        case "repoitemlearningobject":
            return <RepoItemField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  hasFileDrop={false}
                                  isRequired={props.isRequired}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}
                                  register={props.register}
                                  name={props.name}
                                  itemToComponent={getRelatedLearningObjectRepoItemRow}
                                  setValue={props.setValue}
                                  showEmptyState={true}
                                  addText={t("learningobject_field.add")}
                                  emptyText={t('learningobject_field.empty')}
                                  formReducerState={props.formReducerState}/>;
        case "attachment":
            return <RepoItemField readonly={props.readonly}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  hasFileDrop={true}
                                  isRequired={props.isRequired}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}
                                  register={props.register}
                                  name={props.name}
                                  itemToComponent={getRelatedAttachmentRepoItemRow}
                                  setValue={props.setValue}
                                  showEmptyState={true}
                                  emptyText={t('attachment_field.empty')}
                                  formReducerState={props.formReducerState}/>;
        case "repoitems":
            return <RepoItemField readonly={true}
                                  defaultValue={props.defaultValue}
                                  isValid={props.isValid}
                                  hasFileDrop={false}
                                  isRequired={props.isRequired}
                                  options={props.options}
                                  hasError={props.hasError}
                                  onChange={(event) => onChange(event)}
                                  register={props.register}
                                  name={props.name}
                                  itemToComponent={getRelatedLearningObjectRepoItemRow}
                                  setValue={props.setValue}
                                  formReducerState={props.formReducerState}/>;
        case "file":
            return <FileField readonly={props.readonly}
                              isValid={props.isValid}
                              options={props.options}
                              hasError={props.hasError}
                              onChange={onChange}
                              isRequired={props.isRequired}
                              file={props.file}
                              defaultValue={props.defaultValue}
                              register={props.register}
                              name={props.name}
                              setValue={props.setValue}/>;
        case "person":
            return <PersonField readonly={props.readonly}
                                isValid={props.isValid}
                                options={props.options}
                                hasError={props.hasError}
                                onChange={onChange}
                                isRequired={props.isRequired}
                                formReducerState={props.formReducerState}
                                defaultValue={props.defaultValue}
                                person={props.person}
                                register={props.register}
                                name={props.name}
                                setValue={props.setValue}/>;
        case "repoitem":
            return <SingleRepoItemField readonly={props.readonly}
                                        isValid={props.isValid}
                                        options={props.options}
                                        hasError={props.hasError}
                                        onChange={onChange}
                                        isRequired={props.isRequired}
                                        formReducerState={props.formReducerState}
                                        defaultValue={props.defaultValue}
                                        relatedRepoItem={props.relatedRepoItem}
                                        register={props.register}
                                        name={props.name}
                                        setValue={props.setValue}/>;
        case "tree-multiselect":
            return <TreeMultiSelectField readonly={props.readonly}
                                         defaultValue={props.defaultValue}
                                         isValid={props.isValid}
                                         hasFileDrop={false}
                                         isRequired={props.isRequired}
                                         addText={t('vocabulary_field.add')}
                                         options={props.options}
                                         retainOrder={props.retainOrder}
                                         hasError={props.hasError}
                                         onChange={(event) => onChange(event)}
                                         register={props.register}
                                         name={props.name}
                                         itemToComponent={getValueRow}
                                         setValue={props.setValue}
                                         showEmptyState={true}
                                         emptyText={t('treemultiselect_field.empty')}
                                         formReducerState={props.formReducerState}/>;
        default:
            return null;
    }
}

export function Required(props) {
    return <i className={"fas fa-star-of-life field-required"}/>
}

export function Label(props) {
    return <label className="field-label">{props.text}</label>;
}

export function Tooltip(props) {
    const popup = useRef();
    const [isTooltipShown, setIsTooltipShown] = useState(false);
    const [isOutsideWindow, setIsOutsideWindow] = useState(null);
    useOutsideElementClicked(() => setIsTooltipShown(false), popup);

    useEffect(() => {
        if (isTooltipShown && !isOutsideWindow) {
            setIsOutsideWindow(isOutsideViewport(popup))
        }
    }, [isTooltipShown])

    return <div className="field-tooltip"
                onMouseEnter={() => {
                    setIsTooltipShown(true)
                }}
                onMouseLeave={() => {
                    setIsTooltipShown(false)
                }}
    >
        <div className={"info-icon-wrapper"}>
            <i className={"fas fa-info"}/>
        </div>
        {isTooltipShown &&
        <div>
            <div className={`tooltip-popup ${isOutsideWindow ? "left" : "right"}`}>
                <div className={"tooltip-content"} ref={popup} dangerouslySetInnerHTML={{__html: getText()}}>
                </div>
                <FontAwesomeIcon className={"arrow-left"} icon={isOutsideWindow ? faCaretRight : faCaretLeft}/>
            </div>
        </div>
        }
    </div>;

    function getText() {
        const arrayOfWords = props.text ? props.text.split(' ') : []

        let stringToReturn = ''
        arrayOfWords.forEach((word) => {
            if (ValidationHelper.isURL(word)) {
                let url = word.trim()
                if (!(url.startsWith("https://") || url.startsWith("http://"))) {
                    url = "https://" + url
                }
                stringToReturn += `<a class="tooltip-link" href="${url}" target="_blank">${word}</a> `
            } else {
                stringToReturn += (word + ' ')
            }
        })
        return `<span>${stringToReturn}</span>`
    }

    function isOutsideViewport(element) {
        const rect = element.current.getBoundingClientRect()
        return (
            rect.right >= (window.innerWidth || document.documentElement.clientWidth)
        )
    }
}

function DateTimeField(props) {
    let valueToShow = null;
    if (props.field.value && !isNaN(props.field.value)) {
        valueToShow = props.field.value
    } else if (!props.field.readonly) {
        valueToShow = Date.now();
    }
    let date = valueToShow ? new Date(valueToShow) : new Date(); //start with timezone as offset

    //Set time to current time as default if no value is set
    useEffect(() => {
        if (!props.field.readonly && !props.field.value) {
            let changeEvent = {
                target: {
                    value: null
                }
            };
            changeEvent.target.value = date.getTime();
            props.onChange(changeEvent);
        }
    }, []);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const dateString = `${year}-${month}-${day}`;

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const timeString = `${hours}:${minutes}`;

    const dateDOMRef = useRef();
    const timeDOMRef = useRef();

    return <div className="datetime-holder">
        <input type="date"
               ref={dateDOMRef}
               className={"form-field-input datetime" + (props.field.readonly ? ' disabled' : '')}
               disabled={props.field.readonly}
               defaultValue={valueToShow ? dateString : null}
               onChange={(e) => {
                   if (!props.field.readonly) {
                       dateStringToFieldValueBeforeOnChange(e);
                   }
               }}/>

        <input type="time"
               ref={timeDOMRef}
               className={"form-field-input datetime" + (props.field.readonly ? ' disabled' : '')}
               disabled={props.field.readonly}
               defaultValue={valueToShow ? timeString : null}
               onChange={(e) => {
                   if (!props.field.readonly) {
                       dateStringToFieldValueBeforeOnChange(e);
                   }
               }}/>
    </div>;

    function dateStringToFieldValueBeforeOnChange(event) {
        const inputDate = dateDOMRef.current.value;
        const inputTime = timeDOMRef.current.value;

        let inputDateTime = null;
        if (inputTime && inputDate) {
            inputDateTime = (new Date(Date.parse(inputDate + 'T' + inputTime + ':00'))).getTime();
        }

        let changeEvent = {
            target: {
                value: inputDateTime
            }
        };
        props.onChange(changeEvent);
    }
}

function getRelatedLinkRepoItemRow(valuePart, onItemAction, readonly, t) {
    return <SortableRow>
        {!readonly && <DragHandle/>}
        <RelatedRepoitemTitle>
            <MarkedupLink href={valuePart.summary.url}
                          target={"_blank"} enabled={readonly}>{valuePart.summary.title}</MarkedupLink>
        </RelatedRepoitemTitle>
        <RelatedRepoitemSubtitle>{valuePart.summary.subtitle}</RelatedRepoitemSubtitle>
        {!readonly && <i className="fas fa-edit edit-icon" onClick={
            () => {
                onItemAction({
                        type: "edit",
                        value: valuePart.id
                    }
                )
            }
        }/>}
        {!readonly && <i className="fas fa-trash delete-icon" onClick={
            () => {
                const confirmAction = () => onItemAction({type: "delete", value: valuePart.id})
                VerificationPopup.show(t('verification.repoItem.delete_repoitemlink.title'), t('verification.repoItem.delete_repoitemlink.subtitle'), confirmAction)
            }
        }/>}
    </SortableRow>
}

function getRelatedResearchObjectRepoItemRow(valuePart, onItemAction, readonly, t) {
    var titleElement = valuePart.summary.title

    if (valuePart.summary.repoItem !== undefined && valuePart.summary.repoItem.permissions.canView) {
        //try to link through another repoitem, e.g. RepoItemLearningObject
        titleElement =
            <MarkedupLink href={'../publications/' + valuePart.summary.repoItem.id}
                          target={"_blank"} enabled={readonly}>{valuePart.summary.title}</MarkedupLink>
    } else if (valuePart.summary.permissions.canView && valuePart.summary.repoItem === undefined) {
        //try to link to another repoitem, e.g. LearningObject
        titleElement =
            <MarkedupLink href={'../publications/' + valuePart.summary.id}
                          target={"_blank"} enabled={readonly}>{valuePart.summary.title}</MarkedupLink>
    }

    return <SortableRow disabled={readonly}>
        {!readonly && <DragHandle/>}
        <RelatedRepoitemTitle>
            {titleElement}
        </RelatedRepoitemTitle>
        {!readonly && <i className="fas fa-edit edit-icon" onClick={
            () => {
                onItemAction({
                        type: "edit",
                        value: valuePart.id
                    }
                )
            }
        }/>}
        {!readonly && <i className="fas fa-trash delete-icon" onClick={
            () => {
                const confirmAction = () => onItemAction({type: "delete", value: valuePart.id})
                VerificationPopup.show(t('verification.repoItem.delete_repoitemresearchobject.title'), "", confirmAction)
            }
        }/>}
    </SortableRow>
}


function getRelatedAttachmentRepoItemRow(valuePart, onItemAction, readonly, t) {
    return <SortableRow disabled={readonly}>
        {!readonly && <DragHandle/>}
        <RelatedRepoitemTitle>
            <MarkedupLink href={valuePart.summary.url} target={"_blank"} enabled={readonly}
                          onClick={(e) => {
                              e.preventDefault()
                              Api.downloadFileWithAccessToken(valuePart.summary.url, valuePart.summary.title, false)
                          }}>{valuePart.summary.title}</MarkedupLink>
        </RelatedRepoitemTitle>
        <RelatedRepoitemSubtitle>
            {valuePart.summary.subtitle}
        </RelatedRepoitemSubtitle>
        {!readonly && <i className="fas fa-edit edit-icon" onClick={
            () => {
                onItemAction({
                        type: "edit",
                        value: valuePart.id
                    }
                )
            }
        }/>}
        {!readonly && <i className="fas fa-trash delete-icon" onClick={
            () => {
                const confirmAction = () => onItemAction({type: "delete", value: valuePart.id})
                VerificationPopup.show(t('verification.repoItem.delete_repoitemrepoitemfile.title'), "", confirmAction)
            }
        }/>}
    </SortableRow>
}

function getValueRow(valuePart, onDelete, readonly) {
    return <SortableRow disabled={readonly}>
        <RelatedRepoitemTitle>
            {valuePart.coalescedLabelNL}
        </RelatedRepoitemTitle>
        {!readonly && <i className="fas fa-trash delete-icon" onClick={
            () => {
                onDelete({
                        type: "delete",
                        value: valuePart.value
                    }
                )
            }
        }/>}
    </SortableRow>
}

function getRelatedLearningObjectRepoItemRow(valuePart, onItemAction, readonly, t) {
    var titleElement = valuePart.summary.title

    if (valuePart.summary.repoItem !== undefined && valuePart.summary.repoItem.permissions.canView) {
        //try to link through another repoitem, e.g. RepoItemLearningObject
        titleElement =
            <MarkedupLink href={'../publications/' + valuePart.summary.repoItem.id}
                          target={"_blank"} enabled={readonly}>{valuePart.summary.title}</MarkedupLink>
    } else if (valuePart.summary.permissions.canView && valuePart.summary.repoItem === undefined) {
        //try to link to another repoitem, e.g. LearningObject
        titleElement =
            <MarkedupLink href={'../publications/' + valuePart.summary.id}
                          target={"_blank"} enabled={readonly}>{valuePart.summary.title}</MarkedupLink>
    }

    return <SortableRow disabled={readonly}>
        {!readonly && <DragHandle/>}
        <RelatedRepoitemTitle>
            {titleElement}
        </RelatedRepoitemTitle>
        {!readonly && <i className="fas fa-edit edit-icon" onClick={
            () => {
                onItemAction({
                        type: "edit",
                        value: valuePart.id
                    }
                )
            }
        }/>}
        {!readonly && <i className="fas fa-trash delete-icon" onClick={
            () => {
                const confirmAction = () => onItemAction({type: "delete", value: valuePart.id})
                VerificationPopup.show(t('verification.repoItem.delete_repoitemlearningobject.title'), "", confirmAction)
            }
        }/>}
    </SortableRow>
}

function getPersonInvolvedRepoItemRow(valuePart, onItemAction, readonly, t) {
    var titleElement = valuePart.summary.title
    if (valuePart.summary.person !== undefined && valuePart.summary.person.permissions.canView) {
        titleElement =
            <PersonInvolvedMarkedUp href={'../profile/' + valuePart.summary.person.id}
                          target={"_blank"} enabled={readonly}>{valuePart.summary.title}</PersonInvolvedMarkedUp>
    }
    return <PersonInvolvedRow disabled={readonly}>
        {!readonly && <DragHandle/>}
        <RelatedRepoitemTitle>{titleElement}</RelatedRepoitemTitle>
        <PersonInvolvedSubtitle>{t('language.current_code') === 'nl' ? valuePart.summary.subtitleNL : valuePart.summary.subtitleEN}</PersonInvolvedSubtitle>
        {!readonly && <i className="fas fa-edit edit-icon" onClick={
            () => {
                onItemAction({
                        type: "edit",
                        value: valuePart.id
                    }
                )
            }
        }/>}
        {!readonly && <i className="fas fa-trash delete-icon" onClick={() => {
            const confirmAction = () => onItemAction({type: "delete", value: valuePart.id})
            VerificationPopup.show(t('verification.author.delete.title'), "", confirmAction)
        }}/>}
    </PersonInvolvedRow>
}

const RelatedRepoitemTitle = styled.div`
    flex-grow: 1;
    font-size: 12px;

    //This will happen in read only
    &:not(:first-child) {
        margin-left: 30px;
    }
`;

const MarkedupLink = styled(ThemedA)`
    color: ${props => props.enabled ? '#000000' : 'default'} !important;
    text-decoration: ${props => props.enabled ? 'underline' : 'default'} !important;
`

const PersonInvolvedMarkedUp = styled(ThemedA)`
  color: #000000;
  text-decoration: none;
  margin-left: 12px;
`

const RelatedRepoitemSubtitle = styled.div`
    margin-left: 20px;
    font-size: 12px;
`;

const PersonInvolvedSubtitle = styled(RelatedRepoitemSubtitle)`
    ${maxNumberOfLines(2)}
    min-width: 240px;
    max-width: 240px;
`

const PersonInvolvedRow = styled.div`
  flex-grow: 1;
  background-color: #F8F8F8;
  border-radius: 5px;
  border: 1px solid #F3F3F3;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  ${props => !!props.disabled && `
        border: 1px solid $background-color-dark;
        background-color: transparent;
    `}

  .order-icon {
    color: $vivid-sky;
    cursor: grab;
  }

  .document-icon {
    margin-left: 33px;
  }

  .edit-icon {
    margin-left: 7px;
    font-size: 12px;
    cursor: pointer;
  }

  .delete-icon {
    margin-left: 22px;
    font-size: 12px;
    cursor: pointer;
  }
`

const SortableRow = styled.div`
    flex-grow: 1;
    background-color: #F8F8F8;
    border-radius: 5px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    
    ${props => !!props.disabled && `
        border: 1px solid $background-color-dark;
        background-color: transparent;
    `}

    .order-icon {
        color: $vivid-sky;
        cursor: grab;
    }

    .document-icon {
        margin-left: 33px;
    }

    .edit-icon {
        margin-left: 7px;
        font-size: 12px;
        cursor: pointer;
    }

    .delete-icon {
        margin-left: 22px;
        font-size: 12px;
        cursor: pointer;
    }
`;


const SectionTitleH5 = styled(ThemedH5)`
    flex-grow: 1;
    @media only screen and (max-width: 1250px) {
        padding-right: 0;
    }
`

const SectionTitleH3 = styled(ThemedH3)`
    flex-grow: 1;
    @media only screen and (max-width: 1250px) {
        padding-right: 0;
    }
`

const SectionDescription = styled.div``;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  cursor: pointer;
`;

const SectionColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SectionRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`


const FormSectionHeader = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-left: 10px;
    
    margin-bottom: ${props => props.isExtended ? "10px" : "0"};

    @media only screen and (max-width: 1250px) {
        flex-direction: column;
    }
`
const SectionSubtitle = styled.div`
    ${openSans()}
    font-size: 12px;
    line-height: 16px;
    color: #2D364F;
`

export const FormSectionsContainer = styled.div`
    flex: 1 1 auto;
`;

export const FormSection = styled.div`
    ${roundedBackgroundPointyUpperLeft()};
     ${props => props.isPublicationFlow ? (
        `padding: ${props.isExtended ? '20px 15px 30px 15px' : '20px 15px 20px 15px'};`
    ) : (
        `padding: ${props.isExtended ? '20px 35px 50px 35px' : '20px 35px 20px 35px'};`
    )}
    background-color: white;
    display: ${props => props.isActive ? 'block' : 'none'};
    &:not(:first-child) {
        margin-top: 10px;
    }
`;

export const Step = styled.div`
  margin-bottom: 40px;
`;

export const StepTitle = styled(ThemedH4)`
    margin-bottom: 10px;
`;

export const StepContainer = styled.div``;

export const FoldButton = styled.div`
  float: right;
  top: 20px;
  font-size: 12px;
  font-weight: 400;
  ${openSans};
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer; 
`;

export const FieldRowDisplay = styled.div`
    display: ${props => props.isShown ? 'block' : 'none'};
`;

export const SectionIcon = styled.div`
    background: ${cultured};
    border-radius: 4px;
    width: 33px;
    height: 33px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SectionTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
      white-space: pre;
`;

